import PropTypes from 'prop-types';
import React from 'react';

const CodeInput = ({ data }) => {
  const transformedCode = {
    __html: data,
  };
  return <div dangerouslySetInnerHTML={transformedCode} />;
};

CodeInput.propTypes = {
  data: PropTypes.string,
};

CodeInput.defaultProps = {
  data: '',
};

export default CodeInput;
