import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import imageUrlFor from '@helpers/imageUrlFor';
import ContactBlock from '@components/ContactBlock';
import DealerList from '@components/DealerList';
import ScrollInView from '@utility/ScrollInView';
import SEO from '@components/seo';
import FindRepresentative from '@src/account/FindRepresentative';

const Dealers = () => {
  const dealersData = useStaticQuery(
    graphql`
      query {
        allSanityDealer {
          edges {
            node {
              id
              metaDescription
              metaImage {
                asset {
                  _id
                }
              }
              heading
              naHeading
              naDealers {
                _key
                location
                name
                website
              }
              intHeading
              intDealers {
                _key
                location
                name
                website
              }
              onlineHeading
              onlineDealers {
                _key
                location
                name
                website
              }
            }
          }
        }
      }
    `,
  );

  const dealerNode = dealersData.allSanityDealer.edges[0]?.node;

  return (
    <div>
      <SEO
        title={dealerNode?.heading}
        description={
          dealerNode?.metaDescription ? dealerNode.metaDescription : null
        }
        image={
          dealerNode?.metaImage?.asset
            ? imageUrlFor(dealerNode.metaImage).url()
            : null
        }
      />
      <div className="mt-12 md:mt-30">
        <ScrollInView>
          <div className="wrapper">
            <div className="md:pb-8 border-b border-mono-400">
              <div className="row">
                <div className="col-12 md:col-3 md:offset-1">
                  <h2 className="lg-down:mb-12 type-sans-830 md:type-sans-930">
                    {dealerNode?.heading}
                  </h2>
                </div>
                <div className="col-12 md:col-7 md:offset-1">
                  <h3 className="mb-8 type-sans-630 md:type-sans-730">
                    {dealerNode?.naHeading}
                  </h3>
                  <DealerList dealers={dealerNode?.naDealers} />
                  <h3 className="mb-8 type-sans-630 md:type-sans-730">
                    {dealerNode?.intHeading}
                  </h3>
                  <DealerList dealers={dealerNode?.intDealers} />
                  <h3 className="mb-8 type-sans-630 md:type-sans-730">
                    {dealerNode?.onlineHeading}
                  </h3>
                  <DealerList dealers={dealerNode?.onlineDealers} />
                </div>
              </div>
            </div>
          </div>
        </ScrollInView>
      </div>
      <div className="" id="findRep">
        <div className="wrapper">
          <div className="py-14 md:py-24 border-b border-mono-400">
            <FindRepresentative view="horizontal" />
          </div>
        </div>
      </div>
      <div className="mb-12 md:mb-30">
        <ContactBlock />
      </div>
    </div>
  );
};

export default Dealers;
