import React from 'react';
import PropTypes from 'prop-types';
import Link from '@utility/Link';
import { extractDomain } from '@src/helpers/product';

const DealerList = ({ dealers }) => {
  return (
    <ul className="flex flex-wrap mb-4 -mx-2">
      {dealers.map((dealer) => (
        <li key={dealer._key} className="w-1/2 md:w-1/3 px-2 mb-12">
          <h4 className="type-sans-240">{dealer.name}</h4>
          <p className="type-sans-030">{dealer.location}</p>
          <Link
            to={dealer.website}
            styled={false}
            className="type-sans-030 text-mono-500"
            aria-label={`Go to ${dealer.name}: ${dealer.website}`}
          >
            {extractDomain(dealer.website)}
          </Link>
        </li>
      ))}
    </ul>
  );
};

DealerList.propTypes = {
  dealers: PropTypes.array.isRequired,
};

DealerList.defaultProps = {
  dealers: [],
};

export default DealerList;
